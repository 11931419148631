import styled from "styled-components"



export const AvatarDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  border-radius: 50%;
  background: #00958242;
  color: #009582;
  width: 90px;
  height: 90px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 1rem;
`;

