import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Table,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  CardFooter,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Tooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Label,
  Input,
} from "reactstrap";
import Widget from "../../components/Widget/Widget.js";

import s from "./Dashboard.module.scss";
import "./Dashboard.module.css";
import mock from "./../tables/mock";

import {
  getAllMessages,
  getCampaignList,
  stopSendMessageByDate,
  listAllInstances,
  getResumeStatusMessage,
  postResendMessage,
} from "../../services/Instance/index.js";

import { getAllGroups } from "../../services/Client-Schedule";
import Swal from "sweetalert2";
import { phoneMask } from "../../utils/mask.js";
import { WhatsappLogo } from "phosphor-react";
import { useHistory, Link } from "react-router-dom";
import { postBlackListPhones } from "../../services/BlackList-Numbers/index.js";
import { EnumStatusSend } from "./../../utils/EnumStatusSend";

const Dashboard = ({ customGrow }) => {
  const [list, setList] = useState([]);
  const [groups, setGroups] = useState([]);
  const [instances, setInstances] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [resumeStatus, setResumeMessage] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState([]);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [blacklistNumber, setBlacklistNumber] = useState("");
  const [blacklistReason, setBlacklistReason] = useState("");

  const [listInfoMessages, setListInfoMessages] = useState([]);
  const [isOpenViewMessage, setIsOpenViewMessage] = useState(false);

  const [phones, setPhones] = useState({
    observations: "",
    numbers: [],
  });

  const [filter, setFilter] = useState({
    dateStart: null,
    dateEnd: null,
    dateType: "createdAt",
    numberSend: null,
    status: null,
    limit: 15,
    previusLimit: 0,
    title: [],

  });

  const status = EnumStatusSend.find(status => status.id === Number(listInfoMessages[0]?.send))?.text || 'Desconhecido';
  

  const toggleModal = (number) => {
    setBlacklistNumber(number);
    setIsOpenModal(!isOpenModal);
  };

  const closeModal = () => {
    setIsOpenModal(!isOpenModal);
  };

  const viewMessageModal = () => {
    setIsOpenViewMessage(!isOpenViewMessage);
  };

  const closeViewMessageModal = () => {
    setIsOpenViewMessage(!isOpenViewMessage);
  };

  const [showBtnMoreRegisters, setShowBtnMoreRegisters] = useState(false);

  const [dropdownStates, setDropdownStates] = useState(list.map(() => false));

  const toggleDropdown = (key) => {
    const newDropdownStates = [...dropdownStates];
    newDropdownStates[key] = !newDropdownStates[key];
    setDropdownStates(newDropdownStates);
  };

  const handleSave = async () => {
    if (!blacklistReason.trim()) {
      Swal.fire({
        icon: "warning",
        title: "Aviso",
        text: "Por favor, o campo de Motivos é obrigatório!",
      });
      return;
    }

    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Tem certeza que deseja adicionar este número à blacklist? Ao aceitar nenhuma mensagem será enviada a este contato.",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Carregando, Por favor aguarde...",
          text: "Por favor aguarde",
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        try {
          await postBlackListPhones({
            observations: blacklistReason,
            numbers: [blacklistNumber],
          });

          Swal.close();

          setPhones({
            ...phones,
            observations: "",
            numbers: [],
          });

          Swal.fire({
            icon: "success",
            title: "Sucesso",
            text: "Número adicionado à blacklist com sucesso.",
          });

          setBlacklistReason("");
          setIsOpenModal(false);
          getMessages();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: "Não foi possível adicionar o número à blacklist",
          });
        }
      }
    });
  };

  const toggleTooltip = (index) => {
    setTooltipOpen((prevTooltipOpen) => {
      const newTooltipOpen = [...prevTooltipOpen];
      newTooltipOpen[index] = !newTooltipOpen[index];
      return newTooltipOpen;
    });
  };


  const handleListInstance = () => {
    listAllInstances().then((res) => {
      setInstances(res.data.resultSet);
    });
  };

  const handleListGroup = () => {
    getAllGroups().then((res) => {
      setGroups(res.data.resultSet);
    });
  };

  const handleListCampaign = async () => {
    let filterSearch = {
      ...filter,
    };
    await getCampaignList(filterSearch).then((res) => {
      setCampaign(res.data.resultSet.result);
    });
  };

  const handleChangeFilter = (ev) => {
    if (ev.target.id === "dateStart") {
      setFilter({
        ...filter,
        dateStart: ev.target.value,
      });
    }
    if (ev.target.id === "dateEnd") {
      setFilter({
        ...filter,
        dateEnd: ev.target.value,
      });
    }
    if (ev.target.id === "dateType") {
      setFilter({
        ...filter,
        dateType: ev.target.value,
      });
    }
    if (ev.target.id === "numberSend") {
      setFilter({
        ...filter,
        numberSend: ev.target.value,
      });
    }
    if (ev.target.id === "status") {
      setFilter({
        ...filter,
        status: ev.target.value,
      });
    }
    if (ev.target.id === "instance") {
      setFilter({
        ...filter,
        instance: ev.target.value,
      });
    }
    if (ev.target.id === "title") {
      setFilter({
        ...filter,
        title: ev.target.value,
      });
    }
    if (ev.target.id === "group") {
      setFilter({
        ...filter,
        group: ev.target.value,
      });
    }
    if (ev.target.id === "typeCampaign") {
      setFilter({
        ...filter,
        typeCampaign: ev.target.value,
      });
    }
  };

  const getInfoMessages = async (messageId) => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    getAllMessages(messageId)
      .then(({ data }) => {
        setListInfoMessages(data?.resultSet.result ?? {});
        viewMessageModal();
        Swal.close();
      })
      .catch((error) => {
        const code = !error.response ? "00000" : error.response.data.code;
        const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const getMessages = async (isMore = false) => {
    Swal.fire({
      title: "Carregando, Por favor aguarde...",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    let filterSearch = {
      ...filter,
    };
    if (!isMore) {
      setFilter({
        ...filter,
        previusLimit: 0,
      });

      filterSearch = {
        ...filter,
        previusLimit: 0,
      };
    }

    await getAllMessages(filterSearch).then(({ data }) => {
      setEnableButtons(filter.status === "0" ?? false);
      if (!data?.status) return;
      Swal.close();

      if (isMore) {
        setList((prevList) => [...prevList, ...data.resultSet.result]);
        setTooltipOpen((prevTooltipOpen) => [
          ...prevTooltipOpen,
          ...Array(data.resultSet.result.length).fill(false),
        ]);
        setShowBtnMoreRegisters(data?.resultSet?.result?.length > 0);
      } else {
        setList(data?.resultSet?.result ?? []);
        setTooltipOpen(Array(data?.resultSet?.result.length).fill(false));
        setShowBtnMoreRegisters(data?.resultSet?.result?.length >= 15);
      }
      setFilter({
        ...filter,
        previusLimit: data.resultSet.limit,
      });
    });
  };

  const disableWatingMessage = () => {
    Swal.fire({
      icon: "question",
      title: "Cancelar mensagem",
      html: `Serão canceladas todas as mensagens com status AGUARDANDO de acordo com o filtro acima.`,
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: "confirmar",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Desativando mensagens",
          text: "Por favor aguarde",
          allowOutsideClick: false,
          allowEscapeKey: false,
          didOpen: () => {
            Swal.showLoading();
          },
        });

        stopSendMessageByDate(filter).then((res) => {
          Swal.close();
          getMessages(false);
        });
      }
    });

    return false;
  };

  const handleResendMessages = async (messageId) => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja reenviar a mensagem?",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    }).then((confirm) => {
      if (!confirm.isConfirmed) return;

      Swal.fire({
        title: "Carregando, Por favor aguarde...",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      postResendMessage(messageId)
        .then((ev) => {
          Swal.fire({
            icon: "success",
            title: "Mensagem enviada com sucesso.",
            confirmButtonText: "Fechar",
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          getMessages();
        })
        .catch((err) => {
          const code = !err.response ? "00000" : err.response.data.code;
          const message = !err.response
            ? "Ocorreu um erro inesperado"
            : err.response.data.message;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: `[${code}] ${message}`,
          });
        });
    });
  };

  const handleResumeMessage = () => {
    getResumeStatusMessage(filter).then((res) => {
      setResumeMessage(res.data.resultSet);
    });
  };

  const [enableButtons, setEnableButtons] = useState(false);

  useEffect(() => {
    getMessages(true);
    handleListInstance();
    handleListCampaign();
    handleResumeMessage();
    handleListGroup();
  }, []);

  useEffect(() => {
    localStorage.setItem("previousPage", window.location.href);
  }, []);

  return (
    <div>
      <Row>
        <Col className="pr-grid-col" xs={12} lg={12}>
          <Row className={`d-flex align-items-stretch ${s.container}`}>
            <div className={`${s.col} ${customGrow ? s.customGrow : ''}`}>
              <div className="rounded border bg-white text-center p-3 d-flex flex-column justify-content-center align-items-center">
                <h1>{resumeStatus.wait ?? 0}</h1>
                <h4 className="text-warning mb-0">AGUARDANDO</h4>
                {!filter.instance &&
                  !filter.dateStart &&
                  !filter.dateEnd &&
                  !filter.numberSend &&
                  !filter.status &&
                  !filter.typeCampaign &&
                  filter.title.length === 0 &&
                  !filter.group ? (
                  <p className="mt-0">últimos 15 dias</p>
                ) : null}
              </div>
            </div>
            <div className={`${s.col} ${customGrow ? s.customGrow : ''}`}>
              <div className="rounded border bg-white text-center p-3 justify-content-center align-items-center">
                <h1>{resumeStatus.success ?? 0}</h1>
                <h4 className="text-success mb-0">SUCESSO</h4>
                {!filter.instance &&
                  !filter.dateStart &&
                  !filter.dateEnd &&
                  !filter.numberSend &&
                  !filter.status &&
                  !filter.typeCampaign &&
                  filter.title.length === 0 &&
                  !filter.group ? (
                  <p className="mt-0">últimos 15 dias</p>
                ) : null}
              </div>
            </div>
            <div className={`${s.col} ${customGrow ? s.customGrow : ''}`}>
              <div className="rounded border bg-white text-center p-3 justify-content-center align-items-center">
                <h1>{resumeStatus.error ?? 0}</h1>
                <h4 className="text-danger mb-0">ERRO</h4>
                {!filter.instance &&
                  !filter.dateStart &&
                  !filter.dateEnd &&
                  !filter.numberSend &&
                  !filter.status &&
                  !filter.typeCampaign &&
                  filter.title.length === 0 &&
                  !filter.group ? (
                  <p className="mt-0">últimos 15 dias</p>
                ) : null}
              </div>
            </div>
            <div className={`${s.col} ${customGrow ? s.customGrow : ''}`}>
              <div className="rounded border bg-white text-center p-3 justify-content-center align-items-center">
                <h1>{resumeStatus.cancel ?? 0}</h1>
                <h4 className="text-secondary mb-0">CANCELADO</h4>
                {!filter.instance &&
                  !filter.dateStart &&
                  !filter.dateEnd &&
                  !filter.numberSend &&
                  !filter.status &&
                  !filter.typeCampaign &&
                  filter.title.length === 0 &&
                  !filter.group ? (
                  <p className="mt-0">últimos 15 dias</p>
                ) : null}
              </div>
            </div>
            <div className={`${s.col} ${customGrow ? s.customGrow : ''}`}>
              <div className="rounded border bg-white text-center p-3 justify-content-center align-items-center">
                <h1>{resumeStatus.blacklist ?? 0}</h1>
                <h4 className="text-dark mb-0">BLACKLIST</h4>
                {!filter.instance &&
                  !filter.dateStart &&
                  !filter.dateEnd &&
                  !filter.numberSend &&
                  !filter.status &&
                  !filter.typeCampaign &&
                  filter.title.length === 0 &&
                  !filter.group ? (
                  <p className="mt-0">últimos 15 dias</p>
                ) : null}
              </div>
            </div>
          </Row>
          <Card className="border-0 mb-5 mt-3">
            <CardHeader className="border-0 bg-white">
              <h4 className="headline-2">Filtro</h4>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="8">
                  <FormGroup>
                    <label>Periodo</label>
                    <div className="input-group">
                      <input
                        id="dateStart"
                        type="date"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                      />
                      <input
                        id="dateEnd"
                        type="date"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                      />
                      <select
                        id="dateType"
                        className="form-control form-control-md"
                        onChange={handleChangeFilter}
                      >
                        <option value="createdAt">Data de cadastro</option>
                        <option value="updatedAt">Data de atualização</option>
                      </select>
                    </div>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Número Envio</label>
                    <input
                      id="numberSend"
                      type="text"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Status</label>
                    <select
                      id="status"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    >
                      <option value="">TODOS</option>
                      <option value="1">ENVIADO</option>
                      <option value="0">AGUARDANDO</option>
                      <option value="-1">ERRO</option>
                      <option value="-2">CANCELADO</option>
                      <option value="2">BLACKLIST</option>
                    </select>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Instância</label>
                    <select
                      id="instance"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    >
                      <option value="" selected>
                        Selecione...
                      </option>
                      {instances.length > 0 ? (
                        instances.map((instance, key) => (
                          <option key={key} value={instance.id}>
                            {instance.name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Campanha</label>
                    <input
                      id="title"
                      type="text"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    ></input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Grupo</label>
                    <select
                      id="group"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    >
                      <option value="" selected>
                        Selecione...
                      </option>
                      {groups.length > 0 ? (
                        groups.map((group, key) => (
                          <option key={key} value={group.id}>
                            {group.name}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </select>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <label>Origem</label>
                    <select
                      id="typeCampaign"
                      className="form-control form-control-md"
                      onChange={handleChangeFilter}
                    >
                      <option value="">TODOS</option>
                      <option value="WHATSAPP">WHATSAPP</option>
                      <option value="SMS">SMS</option>
                    </select>
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter className="border-0 bg-white">
              <button
                className="btn btn-success btn-md"
                onClick={() => {
                  getMessages(false);
                  handleResumeMessage();
                }}
              >
                Buscar
              </button>
            </CardFooter>
          </Card>
          <Row className="gutter">
            <Col>
              <Widget>
                <Card className="border-0">
                  <CardHeader className="border-0 bg-white">
                    <Row>
                      <Col md="9" xl="9">
                        <div className={s.tableTitle}>
                          <div className="headline-2">Lista de Mensagens</div>
                        </div>
                      </Col>
                      {enableButtons ? (
                        <Col md="3" xl="3" className="text-right">
                          <Button
                            className="btn btn-sm btn-warning"
                            onClick={disableWatingMessage}
                          >
                            Cancelar envio
                          </Button>
                        </Col>
                      ) : null}
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <div className="widget-table-overflow">
                      {list.length > 0 ? (
                        <>
                          <Table
                            className={`table-striped table-borderless table-hover ${s.statesTable}`}
                            responsive
                          >
                            <thead>
                              <tr>
                                <th className="w-14">AUTHOR</th>
                                <th className="w-14">NUMERO</th>
                                <th className="w-14">ORIGEM</th>
                                <th className="w-14">INSTANCIA</th>
                                <th className="w-14">MENSAGEM</th>
                                <th className="w-14">STATUS</th>
                                <th className="w-14">OPÇÕES</th>
                              </tr>
                            </thead>
                            <tbody>
                              {list.map((message, index) => (
                                <tr key={index}>
                                  <td>
                                    <h5>{message.author}</h5>
                                    <p>
                                      DATA CADASTRO:{" "}
                                      <strong className="badge badge-secondary">
                                        {message.createdAt}
                                      </strong>
                                    </p>
                                    <p>
                                      DATA ENVIO:{" "}
                                      <strong className="badge badge-secondary">
                                        {message.updatedAt}
                                      </strong>
                                    </p>
                                  </td>
                                  <td>
                                    {phoneMask(message.phone)}{" "}
                                    <a
                                      href={`https://wa.me/55${message.phone}`}
                                      target="_blank"
                                      className="btn btn-success p-2 m-0"
                                      alt="Whatsapp"
                                    >
                                      <WhatsappLogo />
                                    </a>
                                  </td>
                                  <td>
                                    {message.typeCampaign === 'SMS' ? (
                                      <span className="badge badge-primary text-light">
                                        <i className="fa-solid fa-comment-sms"></i> SMS
                                      </span>
                                    ) : (message.typeCampaign) === 'WHATSAPP' ? (
                                      <span className="badge badge-success text-light">
                                        <i className="fa-brands fa-whatsapp"></i> WHATSAPP
                                      </span>
                                    ) : (
                                      <span className="badge badge-danger text-light">
                                        ERRO
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <p>
                                      <strong className="badge badge-info">
                                        {message.nameInstance}
                                      </strong>
                                    </p>
                                  </td>
                                  <td className="">
                                    <Tooltip
                                      className="align-self-start text-start w-auto"
                                      placement="top"
                                      isOpen={tooltipOpen[index]}
                                      target={`tooltip-${index}`}
                                      toggle={() => toggleTooltip(index)}
                                      trigger="hover"
                                      delay={{ show: 100, hide: 0 }}
                                      style={{
                                        backgroundColor: "#FFF",
                                        border: "1px solid #C7D0D9",
                                        color: "#000",
                                        fontWeight: "500",
                                        borderRadius: "5px",
                                        width: "15rem",
                                        padding: "10px",
                                        whiteSpace: "pre-wrap",
                                        textAlign: "start",
                                        position: "absolute",
                                        bottom: "10px",
                                      }}
                                    >
                                      {message.message}
                                    </Tooltip>
                                    <div id={`tooltip-${index}`}>
                                      {message?.message?.length > 20
                                        ? `${message?.message?.slice(0, 20)}...`
                                        : message?.message}
                                    </div>
                                  </td>
                                  <td>
                                    {Number(message.send) === 1 ? (
                                      <span className="badge badge-success">
                                        ENVIADO
                                      </span>
                                    ) : Number(message.send) === 0 ? (
                                      <span className="badge badge-warning">
                                        AGUARDANDO
                                      </span>
                                    ) : Number(message.send) === -2 ? (
                                      <span className="badge badge-secondary text-dark">
                                        CANCELADO
                                      </span>
                                    ) : Number(message.send) === 2 ? (
                                      <span className="badge badge-dark text-light">
                                        BLACKLIST
                                      </span>
                                    ) : (
                                      <span className="badge badge-danger">
                                        ERRO
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    <Dropdown
                                      isOpen={dropdownStates[index]}
                                      toggle={() => toggleDropdown(index)}
                                    >
                                      <DropdownToggle
                                        caret
                                        style={{ color: "black" }}
                                      >
                                        OPÇÕES
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() => {
                                            setCampaign({ message });
                                            handleResendMessages(message.id);
                                          }}
                                        >
                                          Reenviar
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            toggleModal(message.phone);
                                          }}
                                        >
                                          BlackList
                                        </DropdownItem>
                                        <DropdownItem>
                                          <Link
                                            to={`/admin/campaignInfo/${message.campaignId}`}
                                            replace={true}
                                            style={{ color: "black" }}
                                          >
                                            Campanha
                                          </Link>
                                        </DropdownItem>
                                        <DropdownItem
                                          onClick={() => {
                                            getInfoMessages({
                                              messageId: message.id
                                            })
                                          }}
                                        >
                                          Visualizar mensagem
                                        </DropdownItem>
                                      </DropdownMenu>
                                    </Dropdown>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                            {showBtnMoreRegisters && (
                              <tbody>
                                <tr>
                                  <td colSpan={12}>
                                    <Button
                                      className="w-100"
                                      onClick={() => {
                                        setFilter({ ...filter });
                                        getMessages(true);
                                      }}
                                    >
                                      Carregar mais...
                                    </Button>
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </Table>
                        </>
                      ) : (
                        <div className="alert alert-secondary">
                          <h4 className="text-dark text-center">
                            Não possuem registros no momento.
                          </h4>
                        </div>
                      )}
                    </div>
                  </CardBody>
                  <Modal
                    isOpen={isOpenModal}
                    toggle={closeModal}
                    className="modal-lg modal-dialog modal-dialog-centered"
                  >
                    <ModalHeader toggle={closeModal}>
                      Cadastrar na BlackList
                    </ModalHeader>
                    <ModalBody style={{ backgroundColor: "#F7F8FB" }}>
                      <Alert className="alert alert-warning mb-3 text-warning">
                        Ao adicionar um número à BlackList, não serão enviadas
                        campanhas para o cliente.
                      </Alert>
                      <Card className="border-0 p-3">
                        <div className="mb-3">
                          <Label>Número</Label>
                          <Input
                            type="tel"
                            value={phoneMask(blacklistNumber)}
                            disabled
                          />
                        </div>
                        <div className="">
                          <Label>Motivo</Label>
                          <Input
                            type="textarea"
                            value={blacklistReason}
                            onChange={(e) => setBlacklistReason(e.target.value)}
                            placeholder="Digite o motivo para o cadastro na blacklist..."
                          />
                        </div>
                      </Card>
                    </ModalBody>
                    <ModalFooter>
                      <Button className="btn btn-success" onClick={handleSave}>
                        Adicionar
                      </Button>
                      <Button color="secondary" onClick={closeModal}>
                        Cancelar
                      </Button>
                    </ModalFooter>
                  </Modal>
                  <Modal
                    isOpen={isOpenViewMessage}
                    toggle={closeViewMessageModal}
                    className="modal-lg modal-dialog modal-dialog-centered"
                  >
                    <ModalHeader toggle={closeViewMessageModal}>
                      Mensagens
                    </ModalHeader>
                    <ModalBody style={{ backgroundColor: "#F7F8FB" }}>
                      {listInfoMessages.map((listInfoMessages) => (
                        <Card className="col-lg-12 col-12 border-0 mb-lg-0 mb-3 mt-3 shadow mr-4 h-auto p-0">
                          <CardBody className="h-auto">
                            <div className="overflow-hidden d-flex flex-lg-row flex-column">
                              <Col className="col-xl-6 col-12">
                                <div className="mb-1">
                                  <div>Nome da campanha</div>
                                  <div>
                                    <p className="font-weight-bold">
                                      {listInfoMessages.nameCampaign}
                                    </p>
                                  </div>
                                </div>
                                <div className="mb-1">
                                  <div>Data de cadastro</div>
                                  <div>
                                    <p className="font-weight-bold">
                                      {listInfoMessages.createdAt}
                                    </p>
                                  </div>
                                </div>
                                <div className="mb-1">
                                  <div>Data de envio</div>
                                  <div>
                                    <p className="font-weight-bold">
                                      {listInfoMessages.updatedAt}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col className="col-xl-6 col-12">
                                <div className="mb-1">
                                  <div>Origem</div>
                                  <div>
                                    <p className="font-weight-bold">
                                      {listInfoMessages.typeCampaign}
                                    </p>
                                  </div>
                                </div>
                                <div className="mb-1">
                                  <div>Instancia</div>
                                  <div>
                                    <p className="font-weight-bold">
                                      {listInfoMessages.nameInstance}
                                    </p>
                                  </div>
                                </div>
                                <div className="mb-1">
                                  <div>Status</div>
                                  <div>
                                    <p className="font-weight-bold">
                                    {status}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </div>
                            <div className="col-12 mt-2">
                              <div className="mb-1">Mensagem</div>
                              <div className="">
                                <p className="font-weight-bold"
                                  style={{ whiteSpace: 'pre-line' }}
                                >
                                  {listInfoMessages.message}
                                </p>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      ))}
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={closeViewMessageModal}>
                        Fechar
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Card>
              </Widget>
            </Col>
          </Row>
        </Col>
      </Row >
    </div >
  );
};

export default Dashboard;
