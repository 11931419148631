import '@fortawesome/fontawesome-free/css/all.css';
import React, { useEffect, useState } from 'react';
import {
    Col,
    Row,
    Card,
    CardBody,
    CardHeader,
    CardFooter,
    FormGroup,
    Label,
    Input,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    InputGroup,
    InputGroupAddon,
    UncontrolledDropdown,
    Tooltip,
    InputGroupText
} from "reactstrap";

import Swal from 'sweetalert2';
import { moneyMask } from 'utils/mask';
import {
    getResumeStatusService,
    getListBillService,
    registerBillService,
    deleteBillService,
    updateBillService,
    updateCategoryService,
    getCategoryService,
    registerCategoryService,
} from '../../services/Bills';


import { FaPlus, FaMinus } from "react-icons/fa";

const translateNames = (type) => {
    switch (type) {
        case "payment":
            return "A Pagar";
        case "receive":
            return "A Receber";
        case "card":
            return "Cartão";
        case "boleto":
            return "Boleto";
        case "cash":
            return "Dinheiro";
        case "pix":
            return "PIX";
        case "check":
            return "Cheque";
        default:
            return type;
    }
};

const Bills = () => {
    const [categories, setCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [typeOrder, setTypeOrder] = useState('');
    const [description, setDescription] = useState('');
    const [category, setCategory] = useState('');
    const [value, setValue] = useState('');
    const [dateExpire, setDateExpire] = useState('');
    const [datePayment, setDatePayment] = useState('');
    const [paymentForm, setPaymentForm] = useState('');
    const [disable, setDisable] = useState(true);
    const [list, setList] = useState([]);
    const [selectedBillInfo, setSelectedBillInfo] = useState(null);
    const [selectedBillId, setSelectedBillId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [showBtnMoreBills, setShowBtnMoreBills] = useState(false);
    const [showBtnMoreCategories, setShowBtnMoreCategories] = useState(false);
    const [selectedCategoryId, setSelectedCategoryId] = useState(false);
    const [selectedPaymentFormId, setSelectedPaymentFormId] = useState(false);
    const [originalValue, setOriginalValue] = useState('');
    const [resumeMessage, setResumeMessage] = useState([]);
    const [tooltipOpen, setTooltipOpen] = useState([]);

    const [filter, setFilter] = useState({
        typeOrder: null,
        category: null,
        dateExpire: null,
        datePayment: null,
        paymentForm: null,
        dateType: null,
        paymentStatus: null,
        limit: 15,
        previousLimit: 0,
    });

    const [categoriesFilter, setCategoriesFilter] = useState({
        limit: 15,
        previousLimit: 0,
    });


    const toggleTooltip = (index) => {
        setTooltipOpen((prevTooltipOpen) => {
            const newTooltipOpen = [...prevTooltipOpen];
            newTooltipOpen[index] = !newTooltipOpen[index];
            return newTooltipOpen;
        });
    };

    const handleShowEditModal = () => {
        setShowEditModal(false);
        resetFields();
    }

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const openModal = (bill) => {
        setIsModalOpen(true);
        setSelectedBillInfo(bill);
    };

    const toggleModal = () => {
        setShowModal(!showModal);
        resetFields();
    };

    const convertDateFormat = (dateTimeString) => {
        if (!dateTimeString) return '';

        const dateObj = new Date(dateTimeString);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`;
    };

    const listConvertDate = (dateTimeString) => {
        if (!dateTimeString) return '';

        const dateObj = new Date(dateTimeString);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');

        return `${day}/${month}/${year}`;
    };

    const toggleEditModal = (billId, categoryId, paymentFormId) => {
        setSelectedBillId(billId);
        setSelectedCategoryId(categoryId);
        setSelectedPaymentFormId(paymentFormId);

        const billInfo = list.find((bill) => bill.id === billId);
        setSelectedBillInfo(billInfo);

        setDescription(billInfo.description ?? '');
        setValue(billInfo.value ?? '');
        setDateExpire(billInfo.dateExpire ? convertDateFormat(billInfo.dateExpire) : '');
        setDatePayment(billInfo.datePayment ? convertDateFormat(billInfo.datePayment) : '');
        setTypeOrder(billInfo.typeOrder ?? '');
        setCategory(billInfo.categoryName ?? '');
        setPaymentForm(billInfo.paymentFormName ?? '');
        setOriginalValue(billInfo.value ?? '');

        setShowEditModal(true);
    };

    const resetModal = () => {
        setShowModal(false);
        resetFields();
    };

    const handleSave = async () => {
        try {
            if (!typeOrder || !category || !value || !dateExpire) {
                Swal.fire({
                    icon: "error",
                    title: "Campos em branco",
                    text: "Tipo de Conta, Categoria, Valor e Data de Vencimento são obrigatórios",
                });
                return;
            }

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            let finalValue = value;

            if (finalValue !== originalValue) {
                finalValue = finalValue.replace('.', '').replace(',', '.');
            }

            if (finalValue !== originalValue) {
                finalValue = finalValue.replace('.', '').replace(',', '.');
            }
    
            const data = {
                typeOrder: typeOrder,
                description,
                category: category,
                value: finalValue,
                dateExpire,
                datePayment,
                paymentForm: paymentForm || null,
                categoryId: selectedCategoryId || null,
                paymentFormId: selectedPaymentFormId || null,
            };
    
            if (showEditModal) {
                await updateBillService(selectedBillId, data);
            } else {
                await registerBillService(data);
            }

            Swal.fire({
                icon: "success",
                title: "Sucesso!",
                text: showEditModal ? "Conta atualizada com sucesso!" : "Conta cadastrada com sucesso!",
                confirmButtonText: "OK",
                allowOutsideClick: false,
                allowEscapeKey: false,
            });

            resetModal();
            setShowEditModal(false);
            handleResume();

            handleListBills();
        } catch (error) {
            console.error(error);
            const code = !error.response ? "00000" : error.response.data.code;
            const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
            });
        }
    };

    const handleDelete = async (billId) => {
        Swal.fire({
            icon: "question",
            title: "Confirmação",
            text: "Você tem certeza que deseja excluir a conta?",
            showCancelButton: true,
            cancelButtonText: "Não, sair",
            confirmButtonText: "Sim, continuar"
        }).then(async (confirm) => {
            if (!confirm.isConfirmed) return;

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {
                await deleteBillService(billId, 0);

                Swal.fire({
                    icon: "success",
                    title: "Operação realizada com sucesso.",
                    confirmButtonText: "Fechar",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });

                setList((prevList) => prevList.filter((bill) => bill.id !== billId));


            } catch (err) {
                const code = !err.response ? "00000" : err.response.data.code;
                const message = !err.response
                    ? "Ocorreu um erro inesperado"
                    : err.response.data.message;
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `[${code}] ${message}`,
                    showConfirmButton: true,
                });
            }
        });

    };

    const updateCategory = async (categoryId) => {
        Swal.fire({
            icon: "question",
            title: "Confirmação",
            text: "Você tem certeza que deseja excluir a categoria?",
            showCancelButton: true,
            cancelButtonText: "Não, sair",
            confirmButtonText: "Sim, continuar"
        }).then(async (confirm) => {
            if (!confirm.isConfirmed) return;

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            try {
                await updateCategoryService(categoryId, 0);

                Swal.fire({
                    icon: "success",
                    title: "Operação realizada com sucesso.",
                    confirmButtonText: "Fechar",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                });
                fetchCategories()

            } catch (err) {
                const code = !err.response ? "00000" : err.response.data.code;
                const message = !err.response
                    ? "Ocorreu um erro inesperado"
                    : err.response.data.message;
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `[${code}] ${message}`,
                    showConfirmButton: true,
                });
            }
        });
    };

    const handleClearFilters = () => {
        resetFields();
        setFilter({
            typeOrder: null,
            category: null,
            dateExpire: null,
            datePayment: null,
            paymentForm: null,
            limit: 15,
            previousLimit: 0,
        });
        setDisable(true);
    };

    const resetFields = () => {
        setTypeOrder('');
        setDescription('');
        setCategory('');
        setValue('');
        setDateExpire('');
        setDatePayment('');
        setPaymentForm('');
    };

    const handleListBills = async (isMore = false) => {
        try {
            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            let filterSearch = {
                ...filter,
                limit: 15,
            };

            if (!isMore) {
                filterSearch.previousLimit = 0;
            } else {
                filterSearch.previousLimit = list.length;
            }

            const response = await getListBillService(filterSearch);
            const data = response.data;

            if (data.resultSet && Array.isArray(data.resultSet)) {
                if (isMore) {
                    setList((prevList) => [...prevList, ...data.resultSet]);
                } else {
                    setList(data.resultSet || []);
                }
                setShowBtnMoreBills(data.resultSet.length >= 15);
            }

            setFilter({
                ...filter,
                previousLimit: list.length,
            });

            Swal.close();
        } catch (error) {
            console.error(error);
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Ocorreu um erro inesperado",
            });
        }
    };

    const handleChangeFilter = (ev) => {
        const { id, value } = ev.target;
        setFilter(prevFilter => ({
            ...prevFilter,
            [id]: value,
        }));
    };

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name === "value") {
            value = moneyMask(value);
        }

        setValue(value);
    }

    const toggleCategoryModal = () => {
        setShowCategoryModal(!showCategoryModal);
        setCategory('');
    }

    const handleSaveCategory = async () => {
        try {
            if (!category) {
                Swal.fire({
                    icon: "error",
                    title: "Campo em branco",
                    text: "O nome da categoria é obrigatório",
                });
                return;
            }

            Swal.fire({
                title: "Carregando, Por favor aguarde...",
                text: "Por favor aguarde.",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            const data = {
                category: category,
            };

           await registerCategoryService(data);

            Swal.fire({
                icon: "success",
                title: "Sucesso!",
                text: "Categoria cadastrada com sucesso!",
                confirmButtonText: "OK",
                allowOutsideClick: false,
                allowEscapeKey: false,
            });

            fetchCategories();

            setCategory('');

        } catch (error) {
            console.error(error);
            const code = !error.response ? "00000" : error.response.data.code;
            const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
            });
        }
    };

    const resetCategoryModal = () => {
        setShowCategoryModal(!showCategoryModal);
        setCategory('');
    }

    const fetchCategories = async (isMore = false) => {
        try {
            Swal.fire({
                title: "Carregando categorias, por favor aguarde...",
                text: "Por favor aguarde",
                allowOutsideClick: false,
                allowEscapeKey: false,
                didOpen: () => {
                    Swal.showLoading();
                },
            });

            let filterSearch = {
                limit: 15,
            };

            if (isMore) {
                filterSearch.previousLimit = categories.length;
            } else {
                filterSearch.previousLimit = 0;
            }

            const response = await getCategoryService(filterSearch);

            if (response.data.resultSet && Array.isArray(response.data.resultSet)) {
                if (isMore) {
                    setCategories((prevCategories) => [...prevCategories, ...response.data.resultSet]);
                } else {
                    setCategories(response.data.resultSet);
                }

                setShowBtnMoreCategories(response.data.resultSet.length >= 15);
            }

            Swal.close();
        } catch (error) {
            console.error(error);
            const code = !error.response ? "00000" : error.response.data.code;
            const message = !error.response ? "Ocorreu um erro inesperado" : error.response.data.message;
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: `[${code}] ${message}`,
            });
        }
    };

    const handleResume = () => {
        let filterSearch = {
            ...filter,
        };

        getResumeStatusService(filterSearch)
            .then((res) => {
                setResumeMessage(res.data.resultSet);
            })
            .catch((err) => {
                const code = !err.response ? "00000" : err.response.data.code;
                const message = !err.response
                    ? "Ocorreu um erro inesperado"
                    : err.response.data.message;
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `[${code}] ${message}`,
                });
            });
    };

    function formatCurrency(value) {
        return new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
    }

    useEffect(() => {
        handleResume();
        fetchCategories();
        handleListBills(true);
        handleResume();
        fetchCategories();
    }, []);

    return (
        <>
            <Row>
                <Col className="pr-grid-col" xs={12} lg={12}>
                    <Row>
                        <Col md={4}>
                            <div className="rounded border bg-white text-center p-3">
                                <h3>{formatCurrency(resumeMessage.totalPayment ?? 0)}</h3>
                                <h5 className="text-danger mb-0">DESPESAS</h5>

                                {filter.dateType !== 'Data de Cadastro' && (!filter.category && !filter.typeOrder && !filter.dateExpire && !filter.datePayment && !filter.paymentForm && !filter.paymentStatus) ? (
                                    <p className="mt-1" style={{ fontSize: "0.9rem" }}>Últimos 15 dias</p>
                                ) : null}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="rounded border bg-white text-center p-3">
                                <h3>{formatCurrency(resumeMessage.totalReceive ?? 0)}</h3>
                                <h5 className="text-success mb-0">RECEBIMENTO</h5>
                                {filter.dateType !== 'Data de Cadastro' && (!filter.category && !filter.typeOrder && !filter.dateExpire && !filter.datePayment && !filter.paymentForm && !filter.paymentStatus) ? (

                                    <p className="mt-1" style={{ fontSize: "0.9rem" }}>Últimos 15 dias</p>
                                ) : null}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div className="rounded border bg-white text-center p-3">
                                <h3>{formatCurrency(resumeMessage.balance ?? 0)}</h3>
                                <h5 className="text-primary mb-0">SALDO</h5>
                                {filter.dateType !== 'Data de Cadastro' && (!filter.category && !filter.typeOrder && !filter.dateExpire && !filter.datePayment && !filter.paymentForm && !filter.paymentStatus) ? (
                                    <p className="mt-1" style={{ fontSize: "0.9rem" }}>Últimos 15 dias</p>
                                ) : null}
                            </div>
                        </Col>
                    </Row>
                </Col>

                <Modal isOpen={showModal} toggle={resetModal} className="modal-xl">
                    <ModalHeader toggle={resetModal}>Registrar nova Conta</ModalHeader>
                    <ModalBody>

                        <Card className='border-0'>
                            <CardBody>
                                <FormGroup>
                                    <Label>
                                        Tipo de Conta <span className="text-danger">*</span>
                                    </Label>
                                    <select
                                        id="typeOrderRegister"
                                        className="form-control form-control-md"
                                        value={typeOrder}
                                        onChange={(e) => setTypeOrder(e.target.value)}
                                    >
                                        <option value="">Selecione...</option>
                                        <option value="payment">A pagar</option>
                                        <option value="receive">A receber</option>
                                    </select>
                                </FormGroup>
                                <FormGroup>
                                    <Label>
                                        Categoria <span className="text-danger">*</span>
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="categoryRegister"
                                            className="form-control form-control-md"
                                            type="select"
                                            value={category}
                                            onChange={(e) => {
                                                const categoryId = e.target.value;
                                                setCategory(categoryId);
                                                setSelectedCategoryId(categoryId);
                                            }}
                                        >
                                            <option value="">Selecione...</option>
                                            {categories.map((category, key) => (
                                                <option key={category.id} value={category.id}>{category.name}</option>
                                            ))}
                                        </Input>
                                        <InputGroupAddon addonType="append">
                                            <Button color="info"
                                                onClick={toggleCategoryModal}
                                            >
                                                <FaPlus />
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Valor <span className="text-danger">*</span></Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>R$</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="text"
                                            name="value"
                                            value={value}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Método de Pagamento</Label>
                                    <select
                                        id="paymentFormRegister"
                                        className="form-control form-control-md"
                                        value={paymentForm}
                                        onChange={(e) => setPaymentForm(e.target.value)}
                                    >
                                        <option value="">Selecione...</option>
                                        <option value="cash">Dinheiro</option>
                                        <option value="card">Cartão</option>
                                        <option value="boleto">Boleto</option>
                                        <option value="pix">Pix</option>
                                        <option value="check">Cheque</option>
                                        <option value="others">Outros</option>
                                    </select>
                                </FormGroup>
                                <div className="d-flex flex-lg-row flex-column">
                                    <FormGroup className='col-lg-6 col-12 p-0 mr-1'>
                                        <Label>Data de Pagamento</Label>
                                        <Input
                                            type="date"
                                            value={datePayment}
                                            onChange={(e) => setDatePayment(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup className='col-lg-6 col-12 p-0'>
                                        <Label>
                                            Data de Vencimento <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="date"
                                            value={dateExpire}
                                            onChange={(e) => setDateExpire(e.target.value)}
                                        />
                                    </FormGroup>
                                </div>
                                <FormGroup>
                                    <Label>Descrição</Label>
                                    <Input
                                        style={{ height: '150px' }}
                                        type="textarea"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <label>
                            Os campos com <span className="text-danger">* </span>
                            são obrigatorios
                        </label>
                        <Button
                            color="secondary"
                            onClick={resetModal}
                        >
                            Fechar
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleSave}
                        >
                            Salvar
                        </Button>
                    </ModalFooter>
                </Modal>

                <Col className="pr-grid-col mt-3" xs={12} lg={12}>
                    <Card className="border-0 mb-5 mt-3 shadow">
                        <CardHeader className="bg-white border-0">
                            <h4 className="headline-2">Filtro</h4>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md="12" lg="12">
                                    <FormGroup>
                                        <label>Periodo</label>
                                        <div className="input-group">
                                            <input
                                                id="datePayment"
                                                type="date"
                                                className="form-control form-control-md"
                                                onChange={handleChangeFilter}
                                                value={filter.datePayment || ''}
                                            />
                                            <input
                                                id="dateExpire"
                                                type="date"
                                                className="form-control form-control-md"
                                                onChange={handleChangeFilter}
                                                value={filter.dateExpire || ''}
                                            />
                                            <select
                                                id="dateType"
                                                className="form-control form-control-md"
                                                onChange={handleChangeFilter}
                                                value={filter.dateType || ''}
                                            >
                                                <option value="createdAt">Data de Cadastro</option>
                                                <option value="datePayment">Data de Pagamento</option>
                                                <option value="dateExpire">Data de Vencimento</option>
                                            </select>
                                        </div>
                                    </FormGroup>
                                </Col>
                                <Col md="6" lg="4">
                                    <FormGroup>
                                        <Label for="typeOrder">Tipo de Conta</Label>
                                        <select
                                            id="typeOrder"
                                            className="form-control form-control-md"
                                            value={filter.typeOrder || ''}
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Selecione...</option>
                                            <option value="payment">A Pagar</option>
                                            <option value="receive">A Receber</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6" lg="4">
                                    <FormGroup>
                                        <Label for="category">Categoria</Label>
                                        <Input
                                            id="category"
                                            className="form-control form-control-md"
                                            type="select"
                                            value={filter.category || ''}
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Selecione...</option>
                                            {categories.map((category, key) => (
                                                <option key={category.id} value={category.name}>{category.name}</option>
                                            ))}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col md="6" lg="4">
                                    <FormGroup>
                                        <Label for="paymentForm">Método de Pagamento</Label>
                                        <select
                                            id="paymentForm"
                                            className="form-control form-control-md"
                                            value={filter.paymentForm || ''}
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Selecione</option>
                                            <option value="cash">Dinheiro</option>
                                            <option value="card">Cartão</option>
                                            <option value="boleto">Boleto</option>
                                            <option value="pix">Pix</option>
                                            <option value="check">Cheque</option>
                                            <option value="others">Outros</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                                <Col md="6" lg="4">
                                    <FormGroup>
                                        <Label for="typeOrder">Status de Pagamento</Label>
                                        <select
                                            id="paymentStatus"
                                            className="form-control form-control-md"
                                            value={filter.paymentStatus || ''}
                                            onChange={handleChangeFilter}
                                        >
                                            <option value="">Selecione...</option>
                                            <option value="paid">Pago</option>
                                            <option value="awaiting">Aguardando Pagamento</option>
                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>
                        </CardBody>
                        <CardFooter className="d-flex flex-sm-row flex-column pt-0 border-0 bg-white">
                            <Button
                                color="primary"
                                onClick={() => {
                                    handleListBills(false);
                                    handleResume();
                                }}
                            >
                                Listar
                            </Button>
                            <Button
                                className='ml-sm-2 ml-0 mt-sm-0 mt-2'
                                color="secondary"
                                disabled={!filter.datePayment && !filter.dateExpire && !filter.typeOrder && !filter.category && !filter.paymentForm && !filter.paymentStatus}
                                onClick={handleClearFilters}
                            >
                                Limpar filtro
                            </Button>
                            <Button
                                color="success"
                                className="ml-sm-2 ml-0 mt-sm-0 mt-2"
                                onClick={toggleModal}
                            >
                                Cadastrar
                            </Button>
                        </CardFooter>
                    </Card>
                </Col>

                <Col>
                    <Card className="border-0">
                        <CardHeader className="border-0 bg-white">
                            <Row>
                                <Col md="9" xl="9">
                                    <div className="">
                                        <div className="headline-2">Lista de contas</div>
                                    </div>
                                </Col>
                            </Row>
                        </CardHeader>
                        <CardBody>
                            <div className="widget-table-overflow text-center">
                                {list.length > 0 ? (
                                    <>
                                        <Table
                                            className={`table-striped table-borderless table-hover`}
                                            responsive
                                        >
                                            <thead>
                                                <tr>
                                                    <th className="w-14">VALOR</th>
                                                    <th className="w-14">TIPO DE CONTA</th>
                                                    <th className="w-14">DATA PAGAMENTO</th>
                                                    <th className="w-14">DATA VENCIMENTO</th>
                                                    <th className="w-14">CATEGORIA</th>
                                                    <th className="w-14">METODO DE PAGAMENTO</th>
                                                    <th className="w-14">DESCRIÇÃO</th>
                                                    <th className="w-14">STATUS</th>
                                                    <th className="w-14">OPÇÕES</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {list.map((bills, key) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <p>
                                                                <strong>R$ {moneyMask(bills.value)}</strong>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {bills.typeOrder === 'receive' ? (
                                                                    <span className="badge badge-info">
                                                                        {translateNames(bills.typeOrder)}
                                                                    </span>
                                                                ) : (
                                                                    <span className="badge badge-warning">
                                                                        {translateNames(bills.typeOrder)}
                                                                    </span>)}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <strong>{listConvertDate(bills.datePayment)}</strong>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                <strong className="text">
                                                                    {listConvertDate(bills.dateExpire)}
                                                                </strong>
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {bills.categoryName}
                                                            </p>
                                                        </td>
                                                        <td>
                                                            <p>
                                                                {translateNames(bills.paymentFormName)}
                                                            </p>

                                                        </td>
                                                        <td className="">
                                                            <Tooltip
                                                                className="align-self-start text-start w-auto"
                                                                placement="top"
                                                                isOpen={tooltipOpen[key]}
                                                                target={`tooltip-${key}`}
                                                                toggle={() => toggleTooltip(key)}
                                                                trigger="hover"
                                                                delay={{ show: 100, hide: 0 }}
                                                                style={{
                                                                    backgroundColor: "#FFF",
                                                                    border: "1px solid #C7D0D9",
                                                                    color: "#000",
                                                                    borderRadius: "5px",
                                                                    width: "15rem",
                                                                    padding: "10px",
                                                                    whiteSpace: "pre-wrap",
                                                                    textAlign: "start",
                                                                    position: "absolute",
                                                                    bottom: "10px"
                                                                }}
                                                            >
                                                                {bills.description}
                                                            </Tooltip>
                                                            <div id={`tooltip-${key}`}>
                                                                {bills.description.length > 10 ? `${bills.description.slice(0, 10)}...` : bills.description}
                                                            </div>
                                                        </td>
                                                        <td>
                                                            {Number(bills.active) === 1 && (
                                                                <span className="badge badge-success">
                                                                    ATIVO
                                                                </span>
                                                            )}
                                                        </td>
                                                        <td>


                                                            <UncontrolledDropdown>
                                                                <DropdownToggle caret style={{ color: 'black' }}>OPÇÕES</DropdownToggle>
                                                                <DropdownMenu>
                                                                    <DropdownItem onClick={() => { openModal(bills); }}>

                                                                        <i className="fa-solid fa-eye"></i> Informações

                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => toggleEditModal(bills.id, bills.categoryId, bills.paymentFormId)}>

                                                                        <i className="fa-solid fa-pen-to-square"></i> Editar

                                                                    </DropdownItem>
                                                                    <DropdownItem onClick={() => { handleDelete(bills.id); }}>

                                                                        <i className="fa-solid fa-trash-can"></i> Deletar

                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            {showBtnMoreBills && (
                                                <tbody>
                                                    <tr>
                                                        <td colSpan={12}>
                                                            <Button
                                                                className="w-100"
                                                                onClick={() => {
                                                                    setFilter({ ...filter });
                                                                    handleListBills(true);
                                                                }}
                                                            >
                                                                Carregar mais...
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            )}
                                        </Table>
                                    </>
                                ) : (
                                    <div className="alert alert-secondary">
                                        <h4 className="text-dark text-center">
                                            Não possuem registros no momento.
                                        </h4>
                                    </div>
                                )}
                            </div>
                        </CardBody>
                    </Card>
                </Col>

                <Modal isOpen={isModalOpen} toggle={closeModal} className="modal-lg modal-dialog-centered">
                    <ModalHeader toggle={closeModal}>Informações da conta</ModalHeader>
                    <ModalBody>
                        {selectedBillInfo && (
                            <>
                                <FormGroup>
                                    <Label>Valor:</Label>
                                    <Input type="text" value={`R$ ${moneyMask(selectedBillInfo?.value || '')}`} readOnly className="bg-transparent text-success" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Tipo de Conta:</Label>
                                    <Input type="text" value={translateNames(selectedBillInfo?.typeOrder || '')} readOnly className="bg-transparent text-success" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Data de Pagamento:</Label>
                                    <Input type="text" value={listConvertDate(selectedBillInfo?.datePayment || '')} readOnly className="bg-transparent text-success" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Data de Vencimento</Label>
                                    <Input type="text" value={listConvertDate(selectedBillInfo?.dateExpire || '')} readOnly className="bg-transparent text-success" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Categoria:</Label>
                                    <Input type="text" value={translateNames(selectedBillInfo?.categoryName || '')} readOnly className="bg-transparent text-success" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Metodo de Pagamento:</Label>
                                    <Input type="text" value={translateNames(selectedBillInfo?.paymentFormName || '')} readOnly className="bg-transparent text-success" />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Descrição</Label>
                                    <Input type="textarea" style={{ height: '150px' }} value={selectedBillInfo?.description || ''} readOnly className="bg-transparent text-success" />
                                </FormGroup>
                            </>
                        )}
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="secondary"
                            onClick={closeModal}
                        >
                            Fechar
                        </Button>

                    </ModalFooter>
                </Modal>

                <Modal isOpen={showEditModal} toggle={handleShowEditModal} className="modal-xl">
                    <ModalHeader toggle={handleShowEditModal}>Editar Conta</ModalHeader>
                    <ModalBody>
                        <Card className='border-0'>
                            <CardBody>
                                <FormGroup>
                                    <Label for="typeOrder">
                                        Tipo <span className="text-danger">*</span>
                                    </Label>
                                    <Input
                                        type="select"
                                        id="typeOrder"
                                        value={typeOrder}
                                        onChange={(e) => setTypeOrder(e.target.value)}
                                    >
                                        <option value="">Selecione...</option>
                                        <option value="payment">A Pagar</option>
                                        <option value="receive">A Receber</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label for="category">
                                        Categoria <span className="text-danger">*</span>
                                    </Label>
                                    <InputGroup>
                                        <Input
                                            id="categoryRegister"
                                            className="form-control form-control-md"
                                            type="select"
                                            value={category}
                                            onChange={(e) => setCategory(e.target.value)}

                                        >
                                            <option value="">Selecione...</option>
                                            {categories.map((category, key) => (
                                                <option key={category.id} value={category.name}>{category.name}</option>
                                            ))}
                                        </Input>
                                        <InputGroupAddon addonType="append">
                                            <Button color="info"
                                                onClick={toggleCategoryModal}
                                            >
                                                <FaPlus />
                                            </Button>
                                        </InputGroupAddon>
                                    </InputGroup>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Valor <span className="text-danger">*</span></Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>R$</InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="text"
                                            name="value"
                                            value={value}
                                            onChange={handleChange}
                                        />
                                    </InputGroup>
                                </FormGroup>
                                <div className="d-flex flex-lg-row flex-column">
                                    <FormGroup className='col-lg-6 col-12 p-0 mr-1'>
                                        <Label>Data de Pagamento</Label>
                                        <Input
                                            type="date"
                                            id="datePayment"
                                            value={datePayment}
                                            onChange={(e) => setDatePayment(e.target.value)}
                                        />
                                    </FormGroup>
                                    <FormGroup className='col-lg-6 col-12 p-0'>
                                        <Label>
                                            Data de Vencimento <span className="text-danger">*</span>
                                        </Label>
                                        <Input
                                            type="date"
                                            id="dateExpire"
                                            value={dateExpire}
                                            onChange={(e) => setDateExpire(e.target.value)}
                                        />
                                    </FormGroup>
                                </div>
                                <FormGroup>
                                    <Label for="paymentForm">Forma de Pagamento</Label>
                                    <Input
                                        type="select"
                                        id="paymentForm"
                                        value={paymentForm}
                                        onChange={(e) => setPaymentForm(e.target.value)}
                                    >
                                        <option value="">Selecione</option>
                                        <option value="cash">Dinheiro</option>
                                        <option value="card">Cartão</option>
                                        <option value="boleto">Boleto</option>
                                        <option value="pix">Pix</option>
                                        <option value="check">Cheque</option>
                                        <option value="others">Outros</option>
                                    </Input>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Descrição</Label>
                                    <Input
                                        style={{ height: '150px' }}
                                        type="textarea"
                                        value={description}
                                        onChange={(e) => setDescription(e.target.value)}
                                    />
                                </FormGroup>
                            </CardBody>
                        </Card>
                    </ModalBody>
                    <ModalFooter>
                        <label>
                            Os campos com <span className="text-danger">* </span>
                            são obrigatorios
                        </label>
                        <Button color="primary" onClick={handleSave}>Salvar</Button>
                        <Button color="secondary" onClick={handleShowEditModal}>Cancelar</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={showCategoryModal} toggle={resetCategoryModal} className="modal-lg modal-dialog-centered">
                    <ModalHeader toggle={resetCategoryModal}>Registrar Categoria</ModalHeader>
                    <ModalBody>
                        <Col>
                            <Card className='border-0 p-0 shadow mb-4'>
                                <CardBody>
                                    <FormGroup>
                                        <Label>Categoria</Label>
                                        <Input
                                            id="category"
                                            className="form-control form-control-md"
                                            type="text"
                                            value={category}
                                            onChange={(e) => setCategory(e.target.value)}
                                        >
                                        </Input>
                                    </FormGroup>
                                </CardBody>
                                <CardFooter className='bg-white border-0 pt-0 d-flex flex-sm-row flex-column'>
                                    <Button
                                        color="secondary"
                                        className='mr-sm-2 mr-0 mb-sm-0 mb-2'
                                        onClick={resetCategoryModal}
                                    >
                                        Fechar
                                    </Button>
                                    <Button
                                        color="primary"
                                        onClick={handleSaveCategory}
                                    >
                                        Salvar
                                    </Button>
                                </CardFooter>
                            </Card>
                        </Col>

                        <Col className='mt-5'>
                            <Card className="border-0">
                                <CardHeader className="border-0 bg-white">
                                    <Row>
                                        <Col md="9" xl="9">
                                            <div className="">
                                                <div className="headline-2">Lista de Categorias</div>
                                            </div>
                                        </Col>
                                    </Row>
                                </CardHeader>
                                <CardBody>
                                    <div className="widget-table-overflow text-center">
                                        {categories.length > 0 ? (
                                            <>
                                                <Table
                                                    className={`table-striped table-borderless table-hover`}
                                                    responsive
                                                >
                                                    <thead>
                                                        <tr>
                                                            <th className="w-14">NOME</th>
                                                            <th className="w-14">DATA DE CADASTRO</th>
                                                            <th className="w-14">STATUS</th>
                                                            <th className="w-14">OPÇÕES</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {categories.map((category, key) => (
                                                            <tr key={key}>
                                                                <td>
                                                                    <p>
                                                                        <strong>{category.name}</strong>
                                                                    </p>
                                                                </td>

                                                                <td>
                                                                    <p>
                                                                        <strong>{category.createdAt}</strong>
                                                                    </p>
                                                                </td>
                                                                <td>

                                                                    {Number(category.active) === 1 && (
                                                                        <span className="badge badge-success">
                                                                            ATIVO
                                                                        </span>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    <UncontrolledDropdown>
                                                                        <DropdownToggle caret style={{ color: 'black' }}>OPÇÕES</DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem
                                                                                onClick={() => {
                                                                                    updateCategory(category.id);
                                                                                }}
                                                                            >
                                                                                Deletar
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                    {showBtnMoreCategories && (
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={12}>
                                                                    <Button
                                                                        className="w-100"
                                                                        onClick={() => {
                                                                            setCategoriesFilter({ ...categoriesFilter });
                                                                            fetchCategories(true);
                                                                        }}
                                                                    >
                                                                        Carregar mais...
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    )}
                                                </Table>
                                            </>
                                        ) : (
                                            <div className="alert alert-secondary">
                                                <h4 className="text-dark text-center">
                                                    Não possuem registros no momento.
                                                </h4>
                                            </div>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </ModalBody>
                </Modal>
            </Row>
        </>
    );
};

export default Bills;