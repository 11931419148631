import styled, { css } from "styled-components";
import { darken } from "polished";
import { device } from "../../styles/breakpoints";

export const Container = styled.div`
  padding: 0 24px;
  height: 300px;

  @media ${device.desktopLG} {
    height: 575px;
  }

  @media (max-width: 991px) {
    height: auto;
  }
`;

export const HistoryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow : auto;
  align-items: center;

  margin-top: 20px;
  height: 220px;

  &::-webkit-scrollbar {
    background-color: inherit;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #eff2f5;
    border-radius: 8px;
  }

  @media ${device.desktopLG} {
    height: auto;
    max-height: 600px;
  }

  @media (max-width: 991px) {
    height: auto;
    max-height: 600px;
    overlay : auto;
  }
`;

export const HistoryItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  background-color: #F7F8FB90;
  padding: 10px;
  border-bottom: 0.5px solid #96969620;

  cursor: pointer;

  @media screen and (max-width: 575px){
    padding: 10px 20px;
  }
`;

export const Avatar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;
  background: #00958242;
  color: #009582;
  width: 45px;
  height: 45px;
  font-weight: bold;
  text-transform: uppercase;
`;

export const HistoryDescWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  margin-left: 16px;

  .lastMessage {
    color: #a1a5b7;
    font-size: 13px;
    font-weight: 500;
    word-break: break-all;
  }

`;

export const PersonWrapper = styled.div`
  width: 100%;
  position: relative;
  padding: 0 0 30px;

  .username {
    transition: color 0.2s ease;
    color: #009582;
    font-weight: 600;
    font-size: 15px;

    &:hover {
      color: #009582;
    }

  }

  .phone {
    color: #a1a5b7;
    font-size: 13px;
    font-weight: 500;
  }

  .date {
    color: #009582;
    font-size: 11px;
    font-weight: 100;
  }

  .last-message{
    color: #a1a5b7;
    margin-top: 5px;
    position: absolute;
    bottom: 0;

    .message {
      color: #a1a5b7;
      font-size: 0.9rem;
      overflow: hidden;
      text-overflow: ellipsis;
      word-wrap: break-word;
      white-space: nowrap;
    }
  }

  .lastMessage {
    @media ${device.tablet} {
      display: none;
    }

    @media ${device.desktopLG} {
      display: block;
    }

    @media ${device.desktopXL} {
      display: none;
    }

    align-self: flex-end;
  }

  @media ${device.tablet} {
    width: 100%;
  }

  @media ${device.desktopLG} {
    width: 100%;
  }

  @media ${device.desktopXL} {
    width: 100%;
  }

  @media screen and (max-width: 350px){
    flex-direction: column;
    align-items: start;
    gap: 5px;

    .username {
      margin: 5px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background-color: #F7F8FB90;

  padding: 16px 24px;
  border-bottom: 1px solid #eff2f5;

  p {
    transition: color 0.2s ease;
    color: #181c32;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px;

    &:hover {
      color: #009ef7;
    }

  }

  p.phone {
    color: #a1a5b7;
    font-size: 10px;
    font-weight: 500;
  }

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: start;
  }
`;

export const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 800px;
  overflow: auto;

  &::-webkit-scrollbar {
    background-color: inherit;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #eff2f5;
    border-radius: 8px;
  }

  .text-warning{
    width: 100%;
    align-self: center;
  }
`;

export const ContainerMessage = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 600px;
  overflow: auto;
  padding: 30px 30px 40px;

  &::-webkit-scrollbar {
    background-color: inherit;
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #eff2f5;
    border-radius: 8px;
  }

  .text-warning{
    width: 100%;
    align-self: center;
  }
`;

export const ChatBubble = styled.div`

  ${({ type }) => css`
    display: flex;
    flex-direction: column;

    position: relative;
    padding: 16px;
    border-radius: 8px;
    width: fit-content;
    max-width: 400px;
    min-width: 150px;
    margin-bottom: 32px;
    white-space: pre-line;
    word-wrap: break-word;
    overflow-wrap: break-word;
    height: auto;
    top: 20px;

    background: ${type === "sent" ? "#F1FAFF" : "#E0E0E070"};
    align-self: ${type === "sent" ? "flex-end" : "flex-start"};
  `}

  p {
    color: #181c32;
    font-size: 13px;
    font-weight: 500;
  }

  span{
    display: block;
    align-self: flex-start;
    font-size: 10px;
  }

  span.span {
    position: absolute;
    right: 0;
    top: -16px;
    display: block;
    align-self: flex-end;
    font-size: 10px;
    color: #009582;
    font-weight: 400;

    ${({ type }) =>
    type === "received" &&
    css`
        right: unset;
        left: 0;
      `}
  }
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  background-color: #fafbfd;
  z-index: 1;
  border-top: 1px solid #eff2f5;
  padding: 30px;

  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: start;
  } 

  .group{
    z-index: 1;

    .textarea{
      width: 50rem;
    }
  }

  .form-control {
    border: none;
    width: auto;
    height: 100px;
    color: #5e6278;
    font-weight: 500;
    background-color: #F7F8FB90;
    padding: 10px 15px;
    position: relative;
  
    &::placeholder {
      color: #a1a5b7;
      font-size: 14px;
      font-weight: 500;
    }
  
    &::-webkit-scrollbar {
      background-color: inherit;
      width: 8px;
    }
  
    &::-webkit-scrollbar-thumb {
      background: #eff2f5;
      border-radius: 8px;
    }
  
    &:hover,
    &:active,
    &:focus {
      outline: none;
      border: none;
    }
  }

`;

export const FooterOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;


  @media (max-width: 1200px) {
    align-items: start;
    justify-content: flex-start;
    padding: 20px 0;
  } 

  .emoji{
    border-radius: 100%;
    width: 50px;
    height: 50px;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;

    @media (max-width: 1200px) {
      width: 50px;
      height: 50px;
    } 
  }
`;

export const Button = styled.button`
  padding: 10px 20px;
  border: none;
  background: #009ef7;
  border-radius: 6px;

  color: #ffffff;
  font-weight: 500;
  font-size: 14px;

  transition: background-color 0.2s ease;

  &:hover {
    background: ${darken(0.1, "#009EF7")};
  }
`;

export const NotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  padding: 24px;

  height: 100%;
  text-align: center;

  img {
    border-radius: 75px 150px 0 75px;
    max-width: 100%;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
  }
`;

export const CardDiv = styled.div`
  background: #ffffff;
  border-radius: 12px;
  width: 100%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  z-index: 1;

  @media (max-width: 991px) {
    padding: 16px 0;
    height: auto;
  }

  .emoji-picker-popup{
    z-index: 100;
    position: absolute;
    bottom: 5px;
    left: 48%;

    @media (max-width: 1200px) {
     left: 15px;
     align-self: center;
     justify-self: center
    } 

    @media (max-width: 576px) {
      left: 0;
      align-self: center;
      justify-self: center;
      width: 100%;
      overflow-x: scroll;
     }
  }

`;

export const SectionHeader = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1199px) {
    flex-direction: column;
    align-items: flex-start;
  }

`;

export const DivSelect = styled.div`
  position: absolute;
  right: 115px;
  width: 180px;
  

  @media screen and (max-width: 1150px) {
   width: 160px;
   position: absoulte;
   right: 60px;
  }

  @media screen and (max-width: 1199px) {
    position: inherit;
    width: 200px;
    padding: 15px 0 0 5px;
  }
`;

export const DivButton = styled.div`
  position: absolute;
  right: 5px;
  width: 100px;
    
  }
`;