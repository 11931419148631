import React, { useState, useEffect, useRef } from "react";
import { io } from "socket.io-client";
import NotFoundIMG from "../../assets/image.png";

import {
  Avatar,
  Header,
  Body,
  ChatBubble,
  Footer,
  NotFound,
  CardDiv,
  SectionHeader,
  DivSelect,
  DivButton,
  ContainerMessage,
} from "./styles";

import "./component/NavTab.css"

import {
  indexContactChatService,
  indexMessageChatService,
  serviceLinkGroup,
  registerObservation,
  updateUser,
  updateUserIdChat,
  countContacts,
} from "../../services/Chat";

import Swal from "sweetalert2";

import {
  FormGroup,
  InputGroup,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Badge,
  Label,
  Tooltip,
  CardHeader,
  CardBody,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Card,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  Alert,
} from "reactstrap";

import { ChatContactComponent } from "./component/ChatContactComponent";

import Select from "react-select";
import { phoneMask } from "utils/mask";
import { listAllInstanceService } from "../../services/Instance";
import { ChatNewMessageComponent } from "./component/ChatNewMessageComponent";

import parse from "html-react-parser";
import { FormatMessageWhatsApp } from "utils/FormatMessageWhatsApp";
import EventModal from "pages/calendar/Components/EventModal";

import { AvatarDiv } from "./component/ChatStyle";

import Switch from "react-switch";
import { getEvents } from "services/Calendar";

import RegisterGroup from "pages/client-schedule/components/RegisterGroup";
import { getAllGroups } from "services/Client-Schedule";
import {
  getObservationsById,
  getGroupsByClientId,
} from "../../services/Client-Schedule";
import { deleteObservation } from "../../services/Chat";

export const Chat = () => {
  const contactDetailsRef = useRef(null);
  const theEnd = useRef(null);
  const socket = useRef();
  const [tabSelected, setTabSelected] = useState(1);
  const [observation, setObservation] = useState("");
  const [isMdScreen, setIsMdScreen] = useState(window.innerWidth <= 991);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showChat, setShowChat] = useState(true);
  const [showContact, setShowContact] = useState(true);
  const [modalNewMessage, setModalNewMessage] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [shouldUpdateInstances, setShouldUpdateInstances] = useState(true);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [signatureEnabled, setSignatureEnabled] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedName, setEditedName] = useState("");
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [instances, setInstances] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [events, setEvents] = useState([]);
  const [list, setList] = useState([]);
  const [showModalGroup, setShowModalGroup] = useState(false);
  const [showModalRegisterGroup, setShowModalRegisterGroup] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedInstanceId, setSelectedInstanceId] = useState(null);
  const [showBtnMoreContacts, setShowBtnMoreContacts] = useState(false);
  const [filter, setFilter] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState({
    client: {
      name: null,
      phone: null,
      clientPhoneNumberId: null,
      instance: null,
    },
    list: [],
  });
  const [limit, setLimit] = useState(0);
  const [previousLimit, setPreviousLimit] = useState(60);
  const [tabNavSelected, setTabNavSelected] = useState(1);
  const [numberChatService, setNumberChatService] = useState(0);

  const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));

  const getObservations = async (clientId) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    try {
      const { data } = await getObservationsById(clientId);
      Swal.close();
      setList(data.resultSet ?? []);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Ocorreu um erro ao buscar as observações. Tente novamente mais tarde.",
      });
    }
  };

  const getCountChats = async (instanceId) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => Swal.showLoading(),
    });

    try {
      const params = instanceId ? { instanceId: instanceId } : {};

      const { data } = await countContacts(params);
      Swal.close();
      setNumberChatService(data.resultSet ?? 0);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: "Ocorreu um erro ao buscar as observações. Tente novamente mais tarde.",
      });
    }
  };


  const handleDelete = async (obsId, clientId) => {
    const confirmed = await Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: `Você tem certeza que deseja deletar a Observação?`,
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    });

    if (!confirmed.isConfirmed) return;

    Swal.fire({
      title: "Removendo",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const data = { clientId: clientId };
      await deleteObservation(obsId, data);
      Swal.close();
      getObservations(clientId);
    } catch (err) {
      const code = !err.response ? "00000" : err.response.data.code;
      const message = !err.response
        ? "Ocorreu um erro inesperado"
        : err.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const handleOpenContactDetails = () => {
    setShowContactDetails(!showContactDetails);
    setEditMode(false);
    setEditedName("");
    setObservation("");
  };

  const toggleTooltip = () => {
    setTooltipOpen(!tooltipOpen);
  };

  const handleIndexItem = (index) => {
    setSelectedItemIndex(index);
    setShowContactDetails(false);
    setObservation("");
    if (isMdScreen) {
      setShowContact(false);
    }
  };

  const handleChat = (res) => {
    setFilter((prevFilter) => {
      handleListContact({
        filter: prevFilter,
        instanceId: selectedInstanceId,
        limit: limit,
        previousLimit: previousLimit,
      });

      if (
        Number(messages.client.clientPhoneNumberId) !==
        Number(res.clientPhoneNumberId) &&
        res.fromMe <= 0
      ) {
        const audio = new Audio(
          "https://storage.googleapis.com/simulador-fgts/audio/chat-sound-1.mp3"
        );
        audio.play();
        return prevFilter;
      }

      setMessages({
        ...messages,
        list: [...messages.list, res],
      });

      handleForceScrollBottom("message-chat");

      return prevFilter;
    });
  };

  const scrollToBottom = () => {
    theEnd.current?.scrollIntoView();
  };

  const handleForceScrollBottom = (id) => {
    var objDiv = document.getElementById(id);

    if (!objDiv) return;

    objDiv.scrollTop = objDiv.scrollHeight;
  };

  const handleGetMessagesClient = (contact, instanceId) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    indexMessageChatService(contact.clientPhoneNumberId, instanceId)
      .then(({ data }) => {
        Swal.close();
        setMessages({
          client: contact,
          list: data?.resultSet ?? [],
          groups: contact.groups ?? [],
        });
        if (shouldUpdateInstances) {
          if (contact.instanceId && contact.instanceName) {
            setSelectedInstance({
              value: contact.instanceId,
              label: contact.instanceName,
            });
          } else {
            setSelectedInstance(null);
          }
        }

        if (isMdScreen) {
          setShowContact(false);
        }
        setShowChat(true);
        setSelectedItemIndex(contact.clientPhoneNumberId);
        handleForceScrollBottom("message-chat");
      })
      .catch((err) => {
        if (isMdScreen) {
          setShowContact(true);
          setShowChat(false);
        }

        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
        });
      });
  };

  const handleListContact = async (
    filter = {},
    setAtribute = true,
    callback = (res) => {
      return res;
    }
  ) => {
    try {
      const { data } = await indexContactChatService(filter);

      if (data?.resultSet) {
        const previousContactsLength = contacts.length;

        const newContacts = data.resultSet || [];
        setContacts(newContacts);
        setIsLoading(false);

        setShowBtnMoreContacts(newContacts.length >= 30);

        callback(newContacts);
      }
    } catch (err) {
      const code = !err.response ? "00000" : err.response.data.code;
      const message = !err.response
        ? "Ocorreu um erro inesperado"
        : err.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleSendMessage = (message) => {
    let instanceLabel = selectedInstance?.label || "";
    const user = JSON.parse(localStorage.getItem("data_user_izichat_app"));
    const messageContent = signatureEnabled
      ? `*${user.name}*\n${message.message}`
      : message.message;

      if(!instanceLabel){
        instanceLabel = message?.instance;
      }

      if(!instanceLabel){
        throw new Error('Instancia não selecionada');
      }

    socket.current.emit("chat-send", {
      instance: instanceLabel,
      message: messageContent,
      clientPhoneNumberId: message.clientPhoneNumberId,
    });
  };

  const handleToggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCloseConversation = () => {
    setShowDropdown(false);
    setSelectedItemIndex(null);
    setShowContact(true);
    setShowContactDetails(false);
    if (isMdScreen) {
      setShowChat(false);
    }

    setMessages({
      client: {
        name: null,
        phone: null,
        clientPhoneNumberId: null,
        instance: null,
      },
      list: [],
    });

    setShouldUpdateInstances(true);
  };

  const handleShowInstance = () => {
    listAllInstanceService()
      .then(({ data }) => {
        if (!data?.resultSet || data?.resultSet.length <= 0) return;

        const instances = data.resultSet.map((register) => ({
          value: register.id,
          label: register.name,
        }));

        setInstances(instances);
      })
      .catch((err) => {
        const code = !err.response ? "00000" : err.response.data.code;
        const message = !err.response
          ? "Ocorreu um erro inesperado"
          : err.response.data.message;
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          showConfirmButton: true,
        });
      });
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleEditClick = () => {
    setEditedName(messages.client.name || "");
    setEditMode(true);
  };

  const handleSaveName = async (clientId) => {
    const confirmResult = await Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja editar o nome?",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    });

    if (confirmResult.isConfirmed) {
      Swal.fire({
        title: "Carregando",
        text: "Por favor, aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const data = { name: editedName };

      try {
        const response = await updateUser(data, clientId);

        if (response) {
          Swal.fire({
            icon: "success",
            title: "Operação realizada com sucesso.",
            confirmButtonText: "Fechar",
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: true,
          });

          setMessages({
            ...messages,
            client: {
              ...messages.client,
              name: editedName,
            },
          });

          setEditMode(false);

          handleListContact({
            limit: limit,
            previousLimit: previousLimit,
          });
        }
      } catch (err) {
        const code = err.response?.data.code || "00000";
        const message =
          err.response?.data.message || "Ocorreu um erro inesperado";
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: `[${code}] ${message}`,
          showConfirmButton: true,
        });
      }
    }
  };

  const handleConversation = async (contact, conversation) => {
    const confirmResult = await Swal.fire({
      icon: "question",
      title: "Confirmação",
      text:
        conversation > 0
          ? "Deseja iniciar a conversa"
          : "Deseja finalizar a conversa",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    });

    if (!confirmResult.isConfirmed) return;

    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      await updateUserIdChat(contact.clientId).then(() => {
        setMessages({
          ...messages,
          client: {
            ...contact,
            userIdChat: conversation > 0 ? 1 : null,
            userIdChat: user.userId,
            userConversation: user.name
          },
        });

        if (conversation > 0) {
          setTabNavSelected(1);
        } else {
          handleCloseConversation();
        }
        handleListContact();
        getCountChats();


        Swal.close();
      });
    } catch (err) {
      const code = err.response?.data.code || "00000";
      const message =
        err.response?.data.message || "Ocorreu um erro inesperado";
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const handleSaveObservation = async (clientId) => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor, aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    const data = { text: observation };

    try {
      const response = await registerObservation(clientId, data);

      if (response) {
        Swal.fire({
          icon: "success",
          title: "Operação realizada com sucesso.",
          confirmButtonText: "Fechar",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: true,
        });
        setObservation("");
        getObservations(clientId);
      }
    } catch (err) {
      const code = err.response?.data.code || "00000";
      const message =
        err.response?.data.message || "Ocorreu um erro inesperado";
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
        showConfirmButton: true,
      });
    }
  };

  const handleConfirmEdit = () => {
    Swal.fire({
      icon: "question",
      title: "Confirmação",
      text: "Você tem certeza que deseja editar o nome?",
      showCancelButton: true,
      cancelButtonText: "Não, sair",
      confirmButtonText: "Sim, continuar",
    }).then((confirmResult) => {
      if (confirmResult.isConfirmed) {
        handleSaveName(messages.client.clientId);
      }
    });
  };

  const toggleModalGroup = async () => {
    setShowModalGroup(!showModalGroup);
    setSelectedGroup(null);
  };

  const toggleModalRegisterGroup = async () => {
    setShowModalRegisterGroup(!showModalRegisterGroup);
  };

  const handleAddGroup = async (clientId) => {
    try {
      if (!selectedGroup) {
        Swal.fire({
          icon: "error",
          title: "Selecione...",
          text: "A seleção de um grupo é obrigatória!",
        });
        return;
      }

      Swal.fire({
        title: "Carregando",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const data = {
        groups: selectedGroup,
      };

      await serviceLinkGroup(clientId, data);

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Grupo vinculado com Sucesso!",
        confirmButtonText: "OK",
        allowOutsideClick: false,
        allowEscapeKey: false,
      });

      setShowModalGroup(false);
      handleListContact({
        limit: limit,
        previousLimit: previousLimit,
      });
    } catch (error) {
      const code = !error.response ? "00000" : error.response.data.code;
      const message = !error.response
        ? "Ocorreu um erro inesperado"
        : error.response.data.message;
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: `[${code}] ${message}`,
      });
    }
  };

  const handleList = async () => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    try {
      const response = await getEvents();
      const fetchedEvents = response.data.resultSet;

      const formattedEvents = fetchedEvents.map((event) => ({
        ...event,
        start: new Date(event.dateStart),
        end: new Date(event.dateEnd),
        title: event.title,
        description: event.description,
        allDay: event.allDay || false,
      }));

      setEvents(formattedEvents);
      Swal.close();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Erro ao carregar eventos.",
        showConfirmButton: true,
      });
    }
  };

  const getGroup = async () => {
    Swal.fire({
      title: "Carregando",
      text: "Por favor aguarde.",
      allowOutsideClick: false,
      allowEscapeKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const response = await getAllGroups({ active: 1 }).then((res) => {
        Swal.close();
        return res;
      });
      Array.isArray(response.data.resultSet);
      setGroupList([...response.data.resultSet]);
    } catch (error) {
      Swal.close();
      console.error("Erro ao buscar a lista de grupos:", error);
    }
  };

  useEffect(() => {
    const handleEscKeyPress = (e) => {
      if (e.keyCode === 27) {
        handleCloseConversation();
      }
    };

    document.addEventListener("keydown", handleEscKeyPress);

    return () => {
      document.removeEventListener("keydown", handleEscKeyPress);
    };
  }, []);

  useEffect(() => {
    socket.current = io("wss://marketplace.izichat.com.br/", {
      transports: ["websocket"],
      reconnectionDelay: 10000,
      auth: {
        authorization: localStorage.getItem("token_izichat_xpto"),
      },
    });
    return () => socket.current.disconnect();
  }, []);

  useEffect(() => {
    socket.current.on("chat", handleChat);
    socket.current.on("error", (err) => {
      Swal.fire({
        icon: "error",
        title: "Erro",
        text: err.message,
      });
    });
    return () => {
      socket.current.off("chat", handleChat);
    };
  }, [messages]);

  useEffect(() => {
    scrollToBottom();
    if (contacts.length <= 0) {
      handleListContact({
        limit: limit,
        previousLimit: previousLimit,
      });
    }

    const handleResize = () => {
      setShowChat(true);
      setShowContact(true);
      setIsMdScreen(window.innerWidth <= 991);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    handleShowInstance();
    getGroup();
    getCountChats();
  }, []);

  useEffect(() => {
    getGroup();
  }, [showModalRegisterGroup]);

  useEffect(() => {
    const getClientGroups = async () => {
      Swal.fire({
        title: "Carregando",
        text: "Por favor aguarde.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        didOpen: () => Swal.showLoading(),
      });

      try {
        const { data } = await getGroupsByClientId(messages.client.clientId);
        Swal.close();
        const formattedGroups =
          data.resultSet?.map((group) => ({
            value: parseInt(group.id),
            label: group.name,
          })) ?? [];
        setSelectedGroup(formattedGroups);
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: "Ocorreu um erro ao buscar os grupos. Tente novamente mais tarde.",
        });
      }
    };

    if (showModalGroup) {
      getClientGroups();
    }
  }, [showModalGroup, messages.client.clientId, getGroupsByClientId]);

  return (
    <>
      <div className="row d-flex align-items-stretch justify-content-stretch">
        {showContact && (
          <ChatContactComponent
            contacts={contacts}
            handleGetMessagesClient={handleGetMessagesClient}
            isMdScreen={isMdScreen}
            contactSelected={selectedItemIndex}
            handleSelectItem={handleIndexItem}
            handleListContact={(filter) => handleListContact(filter)}
            toggleModal={() => setModalNewMessage(!modalNewMessage)}
            handleCloseConversation={handleCloseConversation}
            setSelectedInstanceId={(instance) => {
              setSelectedInstanceId(instance);
            }}
            selectedInstanceId={selectedInstanceId}
            filter={filter}
            setFilter={(filter) => {
              setFilter(filter);
            }}
            showBtnMoreContacts={showBtnMoreContacts}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            limit={limit}
            previousLimit={previousLimit}
            setPreviousLimit={setPreviousLimit}
            tabNavSelected={tabNavSelected}
            setTabNavSelected={setTabNavSelected}
            numberChatService={numberChatService}
            getCountChats={getCountChats}
          />
        )}

        {(showChat || !isMdScreen) && (
          <div className="d-flex align-content-stretch col-12 col-lg-7 col-xl-8 mt-3 mt-lg-0">
            {showContactDetails && (
              <div
                ref={contactDetailsRef}
                className="col-12 col-lg-9 col-xl-8 position-absolute"
                style={{ right: 0, zIndex: 200, height: "100%" }}
              >
                <CardDiv className="card rounded-0 h-100">
                  <CardHeader className="d-flex align-items-center bg-white border-0">
                    <Button
                      type="button"
                      aria-label="Close"
                      onClick={() => {
                        handleOpenContactDetails();
                      }}
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                        fontSize: "1.5rem",
                      }}
                      color="light"
                      className="rounded-circle p-0 d-flex text-center align-items-center justify-content-center border-0"
                    >
                      <i className="eva eva-close-outline"></i>
                    </Button>
                    <h5 className="mb-0 headline-3">Dados do Contato</h5>
                  </CardHeader>
                  <CardBody className="d-flex align-items-center flex-column">
                    <div className="mr-3">
                      <AvatarDiv>{messages.client.name?.slice(0, 2)}</AvatarDiv>
                    </div>
                    {editMode ? (
                      <InputGroup className="mb-3 w-50">
                        <Input
                          type="text"
                          value={editedName}
                          onChange={(e) => setEditedName(e.target.value)}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleConfirmEdit();
                            }
                          }}
                        />
                      </InputGroup>
                    ) : (
                      <div
                        className="text-center"
                        style={{ fontSize: "1.2rem", cursor: "pointer" }}
                        onClick={handleEditClick}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            handleEditClick();
                          }
                        }}
                        role="button"
                        tabIndex={0}
                      >
                        {messages.client.name}
                      </div>
                    )}
                    <p style={{ fontSize: "1rem", color: "#A1A5B7" }}>
                      {phoneMask(messages.client.phone)}
                    </p>
                    {messages?.client?.userIdChat && (
                      <p>
                        Atendente:{" "}
                        <strong>{messages.client.userConversation}</strong>
                      </p>
                    )}
                    <div
                      className="d-flex ml-lg-3 align-items-center my-md-0 my-1 flex-wrap mt-5"
                      style={{
                        gap: "3px",
                        position: "relative",
                      }}
                    >
                      {messages.groups.map((group, key) => (
                        <div key={key}>
                          <Badge
                            color="primary"
                            className="badge-group"
                          >
                            <span
                              style={{
                                fontSize:
                                  "11px",
                                maxWidth:
                                  "100px",
                                lineHeight:
                                  "11px",
                                textOverflow:
                                  "ellipsis",
                                whiteSpace:
                                  "nowrap",
                                overflow:
                                  "hidden",
                                display:
                                  "flex",
                                alignItems:
                                  "center",
                                justifyContent:
                                  "center",
                              }}
                            >
                              {group.name}
                            </span>
                          </Badge>
                        </div>
                      ))}
                    </div>
                    <Card className="border-0 mt-5 w-100">
                      <CardBody>
                        <Nav style={{ height: "41px" }} tabs>
                          <NavItem>
                            <NavLink
                              className={tabSelected === 1 && "active"}
                              onClick={() => {
                                setTabSelected(1);
                              }}
                            >
                              Observações
                            </NavLink>
                          </NavItem>
                        </Nav>
                      </CardBody>
                      <TabContent
                        style={{ maxHeight: "350px", overflow: "auto" }}
                      >
                        <FormGroup className="w-100 px-3">
                          <Input
                            type="textarea"
                            className="mb-3"
                            style={{
                              maxHeight: "90px",
                              overflow: "auto",
                              resize: "none",
                            }}
                            onChange={(e) => setObservation(e.target.value)}
                            value={observation}
                          />
                          <Button
                            className="mt-3 w-100 bg-success text-white"
                            onClick={() => {
                              handleSaveObservation(messages.client.clientId);
                            }}
                          >
                            Salvar
                          </Button>
                        </FormGroup>
                        <Card className="border-0">
                          <CardHeader className="border-0 bg-white">
                            <Row>
                              <Col md="9" xl="9">
                                <div className="">
                                  <div className="headline-3">
                                    Lista de observações{" "}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <div className="widget-table-overflow">
                              {list.length > 0 ? (
                                <>
                                  {list.map((observation, key) => (
                                    <div
                                      style={{
                                        padding: "5px 20px 20px",
                                        background: "#f5f5f5",
                                        borderRadius: "10px",
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-center">
                                        <p style={{ fontSize: "0.8rem" }}>
                                          <strong>
                                            {observation.createdAt.slice(0, 10)}
                                          </strong>
                                        </p>
                                        <button
                                          className="rounded-circle p-0 d-flex text-center align-items-center justify-content-center border-0"
                                          style={{
                                            width: "40px",
                                            height: "40px",
                                            fontSize: "1.2rem",
                                          }}
                                          onClick={() =>
                                            handleDelete(
                                              observation.id,
                                              observation.clientId
                                            )
                                          }
                                        >
                                          <i className="fa-solid fa-trash-can"></i>
                                        </button>
                                      </div>
                                      <p style={{ whiteSpace: "pre-line" }}>
                                        {observation.text}
                                      </p>
                                    </div>
                                  ))}
                                </>
                              ) : (
                                <div className="alert alert-secondary">
                                  <h4 className="text-dark text-center">
                                    Não possuem registros no momento.
                                  </h4>
                                </div>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </TabContent>
                    </Card>
                  </CardBody>
                </CardDiv>
              </div>
            )}
            <CardDiv className="p-0 position-relative h-100">
              {!messages.client.name ? (
                <NotFound>
                  <img src={NotFoundIMG} alt="select some conversation" />
                </NotFound>
              ) : (
                <>
                  <Header>
                    <section className="w-100 d-flex position-relative align-items-center">
                      <SectionHeader>
                        <div
                          className="d-flex"
                          onClick={() => {
                            handleOpenContactDetails();
                            getObservations(messages.client.clientId);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter" || e.key === " ") {
                              handleOpenContactDetails();
                              getObservations(messages.client.clientId);
                            }
                          }}
                          role="button"
                          tabIndex={0}
                        >
                          <div className="mr-3">
                            <Avatar>{messages.client.name?.slice(0, 2)}</Avatar>
                          </div>
                          <div>
                            <p className="text-success fw-bold">
                              {messages.client.name}
                            </p>
                            <p className="phone">
                              {phoneMask(messages.client.phone)}
                            </p>
                            {messages?.client?.userIdChat && (
                              <p className="text-primary" style={{ fontSize: '10px' }}>
                                <i className="fas fa-headset"></i>{" "}
                                {messages.client.userConversation}
                              </p>
                            )}
                          </div>
                        </div>
                        {instances.length > 1 && (
                          <DivSelect>
                            <Select
                              options={instances}
                              isLoading={instances.length <= 0}
                              value={selectedInstance}
                              placeholder="Selecione..."
                              onChange={(selectedOption) => {
                                setSelectedInstance(selectedOption);
                                setShouldUpdateInstances(false);
                              }}
                            />
                          </DivSelect>
                        )}
                      </SectionHeader>
                      <DivButton>
                        {!messages.client.userIdChat ? (
                          <Button
                            className="rounded-circle p-3 d-flex"
                            color="light"
                            onClick={() => {
                              handleConversation(messages.client, 1);
                            }}
                          >
                            <i className="fas fa-phone text-success"></i>
                          </Button>
                        ) : null}
                        {messages.client.userIdChat && (
                          <Button
                            className="rounded-circle p-3 d-flex"
                            color="light"
                            onClick={() => {
                              handleConversation(messages.client, 0);
                            }}
                          >
                            <i className="fas fa-phone-slash text-danger"></i>
                          </Button>
                        )}
                      </DivButton>
                      <Dropdown
                        style={{
                          position: "absolute",
                          right: "0",
                        }}
                        isOpen={showDropdown}
                        toggle={() => handleToggleDropdown()}
                      >
                        <DropdownToggle
                          style={{ width: "50px", height: "50px" }}
                          color="light"
                          className="rounded-circle p-0 d-flex text-center align-items-center justify-content-center"
                        >
                          <i className="fa fa-ellipsis-v text-success" />
                        </DropdownToggle>
                        <DropdownMenu
                          style={{
                            margin: "5px 7rem 0 0",
                          }}
                        >
                          <DropdownItem onClick={handleCloseConversation}>
                            <i className="fa-solid fa-comment-slash mr-2"></i>
                            Fechar Conversa
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              toggleModal();
                            }}
                          >
                            <i className="fa-solid fa-calendar-days mr-2"></i>
                            Agendar Evento
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              handleOpenContactDetails();
                              getObservations(messages.client.clientId);
                            }}
                          >
                            <i className="fa-solid fa-address-book mr-2"></i>
                            Dados do Contato
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              toggleModalGroup();
                              getGroup();
                            }}
                          >
                            <i className="fa-solid fa-people-group mr-2"></i>
                            Vincular Grupo
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </section>
                  </Header>
                  <Body id="message-chat">
                    <ContainerMessage>
                      {messages && messages.list.length > 0 ? (
                        messages.list.map((conversation, key) => (
                          <ChatBubble
                            key={key}
                            type={conversation.fromMe <= 0 ? "sent" : null}
                          >
                            {conversation.type === "audioMessage" && (
                              <audio className="chat-audio" controls>
                                <source src={conversation.url} type="audio/ogg" />
                                <track kind="captions" />
                              </audio>
                            )}
                            {conversation.type === "videoMessage" && (
                              <video controls className="rounded chat-video">
                                <source src={conversation.url} type="video/mp4" />
                                <track kind="captions" />
                              </video>
                            )}
                            {conversation.type === "imageMessage" && (
                              <a href={conversation.url} target="_blank">
                                <img
                                  src={conversation.url}
                                  alt={conversation.whatsappId}
                                  className="rounded chat-image"
                                />
                              </a>
                            )}
                            <span className="span">{conversation.createdAt}</span>
                            <p>
                              {parse(FormatMessageWhatsApp(conversation.message))}
                            </p>
                            <Badge className="bg-info text-white mt-1">
                              {conversation.instanceName}
                            </Badge>
                          </ChatBubble>
                        ))
                      ) : (
                        <></>
                      )}
                    </ContainerMessage>
                    <div ref={theEnd} />

                    <div>
                      {messages.client.userIdChat && user.userId ? (
                        messages.client.userIdChat == user.userId ? (
                          <Footer className="flex-column align-items-start">
                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen}
                              target="label"
                              toggle={toggleTooltip}
                              style={{
                                backgroundColor: "#FFF",
                                border: "1px solid #C7D0D9",
                                color: "#000",
                                fontWeight: "600",
                                borderRadius: "5px",
                                width: "15rem",
                                padding: "10px",
                                whiteSpace: "pre-wrap",
                                textAlign: "left",
                              }}
                            >
                              Se ativado, a mensagem será enviada com o nome do usuário;
                              se desativado, o nome não será enviado.
                            </Tooltip>
                            <Label
                              className="d-flex align-items-center mb-0"
                              id="label"
                            >
                              <Switch
                                checked={signatureEnabled}
                                onChange={(checked) => setSignatureEnabled(checked)}
                                offColor="#C7D0D9"
                                onColor="#009582"
                                handleDiameter={15}
                                height={10}
                                width={40}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                uncheckedIcon={false}
                                checkedIcon={false}
                              />
                              <span
                                className="ml-2"
                                style={{
                                  fontSize: "13px",
                                  fontWeight: "600",
                                }}
                              >
                                Assinatura
                              </span>
                            </Label>
                            <FormGroup className="w-100 group">
                              <InputGroup>
                                <textarea
                                  id="messageTextarea"
                                  className="form-control w-100 bg-white"
                                  style={{ height: 100, zIndex: 999 }}
                                  placeholder="Digite uma mensagem"
                                  onKeyDown={(e) => {
                                    if (e.key !== "Enter") return;

                                    if (selectedInstance && selectedInstance.label) {
                                      handleSendMessage({
                                        instance: selectedInstance,
                                        clientPhoneNumberId:
                                          messages.client.clientPhoneNumberId,
                                        message: e.target.value,
                                      });

                                      document.getElementById("messageTextarea").value =
                                        "";
                                    } else {
                                      Swal.fire({
                                        icon: "error",
                                        title: "Instância não selecionada",
                                        text: "Selecione uma instância para enviar a mensagem.",
                                      });
                                    }

                                    if (e.preventDefault) e.preventDefault();
                                    return false;
                                  }}
                                />
                              </InputGroup>
                            </FormGroup>
                          </Footer>
                        ) : (
                          <Footer className="flex-column align-items-center">
                            <Alert color="secondary">
                              <p className="text-success" style={{ fontSize: '18px' }}>
                                Essa conversa pertence a(o) atendente {messages.client.userConversation}.
                              </p>
                            </Alert>
                          </Footer>
                        )
                      ) : null}
                      {messages.client.userIdChat == null && (
                        <Footer className="flex-column align-items-center">
                          <Alert color="secondary">
                            <p className="text-success" style={{ fontSize: '18px' }}>
                              Pressione o botão<i className="fa-solid fa-phone mr-2 ml-2"></i>para iniciar.
                            </p>
                          </Alert>
                        </Footer>
                      )}
                    </div>
                  </Body>

                </>
              )}
              <Modal
                isOpen={showModalGroup}
                toggle={toggleModalGroup}
                className="modal-lg modal-dialog-centered"
              >
                <ModalHeader toggle={toggleModalGroup}>
                  Vincular Grupo
                </ModalHeader>
                <ModalBody>
                  <Card className="shadow-none border-0">
                    <Card className="shadow-none border">
                      <CardBody>
                        <FormGroup className="d-flex flex-column">
                          <Label for="groupSelect">
                            Cadastre um novo Grupo
                          </Label>
                          <Button
                            color="success"
                            onClick={toggleModalRegisterGroup}
                            style={{ maxWidth: "150px" }}
                          >
                            Cadastrar
                          </Button>
                        </FormGroup>
                      </CardBody>
                    </Card>

                    <Card className="shadow-none border mt-2">
                      <CardBody>
                        <FormGroup>
                          <Label for="groupSelect">
                            Selecione um ou mais Grupos
                          </Label>
                          <Select
                            isMulti
                            id="groupSelect"
                            options={groupList.map((group) => ({
                              value: parseInt(group.id),
                              label: group.name,
                            }))}
                            onChange={(selectedOption) =>
                              setSelectedGroup(selectedOption)
                            }
                            value={selectedGroup}
                            placeholder="Selecione..."
                          />
                        </FormGroup>
                      </CardBody>
                    </Card>
                  </Card>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggleModalGroup}>
                    Cancelar
                  </Button>
                  <Button
                    color="primary"
                    onClick={() => handleAddGroup(messages.client.clientId)}
                  >
                    Salvar
                  </Button>
                </ModalFooter>
              </Modal>
            </CardDiv>
          </div>
        )}

        <ChatNewMessageComponent
          showModal={modalNewMessage}
          toggleModal={setModalNewMessage}
          sendMessage={handleSendMessage}
          handleListContact={handleListContact}
          handleGetMessagesClient={handleGetMessagesClient}
        />

        <EventModal
          modal={modal}
          toggleModal={toggleModal}
          handleList={handleList}
        />

        <RegisterGroup
          isOpen={showModalRegisterGroup}
          toggleModal={toggleModalRegisterGroup}
          execute={() => {
            getGroup();
          }}
        />
      </div>
    </>
  );
};
